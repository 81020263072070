import React from 'react'
import {BrowserRouter,Routes,Route, Navigate} from "react-router-dom";

import { connect } from "react-redux";
import axios from 'axios';
import { loadStripe } from "@stripe/stripe-js";
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import { actionSet, portalStore} from 'store';
import { checkLogin, logout } from 'store/authentication';

import Error from './pages/onboarding/Error';
import Login from './pages/onboarding/Login';
import SignUp from './pages/onboarding/SignUp'
import SignUpComplete from './pages/onboarding/SignUpComplete'
import Verify from './pages/onboarding/Verify'
import Recover from './pages/onboarding/Recover'
import RecoverCancel from './pages/onboarding/RecoverCancel'
import RecoverReset from './pages/onboarding/RecoverReset'

import Dashboard from './pages/dashboard/index.js';
import EmailChangeConfirm from 'pages/onboarding/EmailChangeConfirm';
import EmailChangeCancel from 'pages/onboarding/EmailChangeCancel';
import TrialRestart from 'pages/onboarding/TrialRestart';
import DistributeLicenseCode from 'pages/onboarding/DistributeLicenseCode';


//SET THE BASE URL
axios.defaults.baseURL = process.env.REACT_APP_HOST_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.REACT_APP_HOST_URL;

//MANAGE CORS
if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "staging") {
  axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
  // axios.defaults.headers.common['Bypass-Tunnel-Reminder'] = true
  //REACT_APP_HOST_URL is the server url
  //TO RUN TESTS ON MOBILE DEVICE VIA localtunnel: REACT_APP_HOST_URL=ttps://cowardly-bird-11.loca.lt npm run start
}

//DISABLE CONSOLE LOG/ETC
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

//DEFAULT HTTP RESPONSE HANDLER
axios.interceptors.response.use((response) => {
  //STATUS 2xx
  return response;
}, (err) => {
  console.log('axiosError', err);
  if (!err.response) {  //network error - service unreachable
    logout();
  }
  else if (err.response.status === 401) { //401 unauthorized
    logout();
  }
  else if (err.response.status === 400) { //400 bad-request
    console.log('ERROR 400!', err.response.data.error);
  }
  return Promise.reject(err);
});


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingLogin: true
    }

    //extract params
    const search = window.location.search;
    const params = new URLSearchParams(search);

    //if there an intentToken, confirm with service and fetch amount_usd
    const intentToken = params.get('intentToken');
    if (intentToken != null) {
      this.handlePaymentIntent(intentToken);
    }

    this.generateFingerprint();
    this.setupStripe();
    checkLogin().then( ()=>{this.setState({checkingLogin: false})} );
  }


  render() {
    if (this.state.checkingLogin) {
      return (<span/>);
    }
    
    return(
      <div className="App">
          <BrowserRouter>
            <Routes>
              {this.props.isLoggedIn ? <>
                <Route path='/' element={<Dashboard />} />
                <Route path='*' element={ <Navigate to='/'/> }/>
                <Route path='/login' element={<Navigate to='/'/> }/>
              </>:<>
                <Route path='/' element={ <Navigate to='/login'/> } />
                <Route path='*' element={ <Navigate to='/login'/> }/>
              </>}
              <Route path='/error' element={<Error/>}/>
              <Route path='/trial-restart' element={<TrialRestart />}/>
              <Route path='distribution/:id' element={<DistributeLicenseCode />}/>
              <Route path='/signup' element={this.props.isLoggedIn ? <Navigate to='/'/> : <SignUp/>}/>
              <Route path='/signupComplete' element={this.props.isLoggedIn ? <Navigate to='/'/> : <SignUpComplete/>}/>
              <Route path='/verify' element={<Verify/>}/>
              <Route path='/recover' element={<Recover/>} />
              <Route path='/recover/cancel' element={<RecoverCancel/>}/>
              <Route path='/recover/reset' element={<RecoverReset/>}/>
              <Route path='/email' element={<EmailChangeConfirm/>}/>
              <Route path='/email/cancel' element={<EmailChangeCancel/>}/>
              <Route path='/login' element = {<Login/> }/>
              
            </Routes>
          </BrowserRouter>
      </div>
    )
  }

  handlePaymentIntent(intentToken) {
    axios.post('api/v1/payment-intent', {token: intentToken})
    .then(resp => {
      if (resp.data.error) {
        console.log(resp.data);
        return;
      }
      
      if (process.env.NODE_ENV !== 'production') {
        console.log(resp.data);
      }
      else if (resp.data.amountUSD) { //send GTM event
        window.dataLayer.push({
          'event': 'ecommerceInteraction',
          ecommerceInteraction: {
              property: 'portal',
              action: 'purchase',
              value: resp.data.amountUSD,
              currency: 'USD',
              product_plan: resp.data.productType,
              order_id: resp.data.referenceId
          }
        });
      }
    });
  }

  generateFingerprint() {
    // create the fingerprint and add it to the portalStore
    const getFingerprint = async ()=> {  //this function should be called via requestIdleCallback
      FingerprintJS.load()
      .then(fp => fp.get())
      .then(result => {
        // This is the visitor identifier:
        const visitorId = result.visitorId;
         this.props.setFingerprint(visitorId);
      })
    };
    
    //trigger the fingerprinting
    if (window.requestIdleCallback) { //something browsers provide when loading is done
      requestIdleCallback(getFingerprint);
    } else {
      setTimeout(getFingerprint, 500);
    }
  }

  

  setupStripe() {
    if (!this.props.stripePromise) {
      //TODO: Stripe publish key should be fetched from the service
      let stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
      stripePromise.then(resp => {
        this.props.setStripePromise(resp);
      });
    }
  }
}

const mapStateToProps = function(state) {
  return {
      stripePromise: state.stripePromise,
      isLoggedIn: state.isLoggedIn,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStripePromise: (data) => dispatch(actionSet('stripePromise',data)),
    setFingerprint: (data) => dispatch(actionSet('fingerprint', data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
