import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { actionSet } from "store";
import axios from "axios";
import PubSub from "pubsub-js";
import moment from "moment";

import {
  isLoaded,
  fetchTrial,
  fetchRecurring,
  cancelRecurring,
  planString,
  priceString,
  fetchBillingInfo,
  startStripeBillingSession,
  symbolForCurrency,
} from "store/resources";

import LoadingSpinner from "components/LoadingSpinner";
import DashboardPanel from "components/DashboardPanel";
import MembershipInfoIcon from "icons/MembershipInfoIcon";
import MusioButton from "components/MusioButton";
import ErrorMessage from "components/ErrorMessage";
import TrialClockIcon from "icons/TrialClockIcon";

import "./style.css";

const MembershipPanel = (props) => {
  const [overlay, setOverlay] = useState("");
  const trial = useSelector((state) => state.trial);
  const licenses = useSelector((state) => state.licenses);
  const recurring = useSelector((state) => state.recurring);

  let active_component = "";
  if (!isLoaded(trial) || !isLoaded(licenses) || !isLoaded(recurring)) {
    return (
      <DashboardPanel gap="1.5rem" icon={<MembershipInfoIcon />}>
        <LoadingSpinner />
      </DashboardPanel>
    );
  }

  let currentOverlay = overlay;
  const showRedeemLink = overlay == "";
  const showPurchaseLink = overlay == "";
  let no_active_membership =
    licenses.lifetime == null &&
    licenses.active == null &&
    licenses.perpetual == null &&
    recurring.status == "inactive" &&
    trial.expired;

  if (currentOverlay == "redeem") {
    return (
      <DashboardPanel gap="1.5rem" icon={<MembershipInfoIcon />}>
        <RedeemMembership onReturn={() => setOverlay("")} />
      </DashboardPanel>
    );
  } else if (licenses.lifetime) {
    return (
      <DashboardPanel gap="1.5rem" icon={<MembershipInfoIcon />}>
        {licenses.lifetime && (
          <LifetimeLicenseDetail license={licenses.lifetime} />
        )}
      </DashboardPanel>
    );
  } else if (no_active_membership) {
    return (
      <DashboardPanel gap="1.5rem" icon={<MembershipInfoIcon />}>
        <NoActiveMembership />
        {showPurchaseLink && (
          <MusioButton
            buttonType="squared"
            size="small"
            background="grey"
            onClick={ ()=>{window.open(process.env.REACT_APP_LICENSE_PURCHASE_URL, '_blank')} }
            text="Purchase License"
          />
        )}
        {showRedeemLink && (
          <div className="user-button">
            <a
              className="musio-link"
              onClick={() => setOverlay("redeem")}
              href="#"
            >
              Redeem License Code
            </a>
          </div>
        )}
      </DashboardPanel>
    );
  }

  return (
    <DashboardPanel gap="1.5rem" icon={<MembershipInfoIcon />}>
      {!trial.expired && <TrialDetail expirationDate={trial.expirationDate} />}
      {recurring.status != "inactive" && (
        <RecurringDetail recurring={recurring} />
      )}
      {licenses.active && <ActiveLicenseDetail license={licenses.active} />}
      {licenses.upcoming && (
        <UpcomingLicensesDetail upcoming={licenses.upcoming} />
      )}
      {licenses.perpetual && (
        <Musio1LicenseDetail license={licenses.perpetual} />
      )}
      {showPurchaseLink && (
          <MusioButton
            buttonType="squared"
            size="small"
            background="grey"
            onClick={ ()=>{window.open(process.env.REACT_APP_LICENSE_PURCHASE_URL, '_blank')} }
            text="Purchase License Code"
          />
        )}
      {showRedeemLink && (
        <div className="user-button">
          <a
            className="musio-link"
            onClick={() => setOverlay("redeem")}
            href="#"
          >
            Redeem License Code
          </a>
        </div>
      )}
    </DashboardPanel>
  );
};

const NoActiveMembership = (props) => {
  return (
    <div className="no-active-membership-container">
      <p className="subtletext">no active membership</p>
    </div>
  );
};

const TrialDetail = (props) => {
  const trial_end = moment(props.expirationDate, "MMM DD YYYY");
  const today = moment.utc();
  const days_left = trial_end.diff(today, "days") + 1; //include start date

  return (
    <div className="trial-container">
      <h3>
        <b>{days_left}</b> days left in trial.
      </h3>
      <TrialClockIcon />
    </div>
  );
};

const LifetimeLicenseDetail = (props) => {
  const since = moment(props.license.startDate, "MMM DD YYYY").format(
    "MMMM DD, YYYY"
  );

  return (
    <div className="lifetime-license-container">
      <h3>Lifetime License Active</h3>
      <p className="subtext">{since}</p>
    </div>
  );
};

const Musio1LicenseDetail = (props) => {
  return (
    <div className="perptual-license-container">
      <h3>Musio 1 Active</h3>
      <p className="subtext">{props.license.startDate}</p>
    </div>
  );
};

const ActiveLicenseDetail = (props) => {
  return (
    <div className="active-license-container">
      <h3>Current License</h3>
      <p className="subtext">
        {props.license.subject}
        {props.license.expirationDate && (
          <span> - expires {props.license.expirationDate}</span>
        )}
      </p>
    </div>
  );
};

const UpcomingLicensesDetail = (props) => {
  return (
    <div className="upcoming-license-container">
      <h3>Upcoming Licenses</h3>
      {props.upcoming.map((l, i) => (
        <p className="subtext" key={i}>
          {i + 1}: {l.subject} → {l.startDate}
        </p>
      ))}
    </div>
  );
};

const RecurringDetail = (props) => {
  const hasUpcomingCarge =
    props.recurring.status == "active" && props.recurring.canceled == false;

  const formatDate = () => {
    let renewalDate = moment(props.recurring.periodEnd, "MMM DD YYYY").format(
      "MMMM-DD-YYYY"
    );
    renewalDate = renewalDate.replace(/(^|-)0+/g, "$1");
    renewalDate = renewalDate.split("-");
    return `${renewalDate[0]} ${renewalDate[1]}, ${renewalDate[2]}`;
  };

  const handleCancel = () => {
    cancelRecurring();
    PubSub.publish("RefreshMembership", true);
  };

  return (
    <div className="recurring-license-container">
      <h3>
        Subscription:{" "}
        <span className="subtext">{planString(props.recurring.plan)}</span>
      </h3>

      {props.recurring.canceled ? (
        <p className="redtext">active until {formatDate()}.</p>
      ) : (
        <p className="subtext" style={{ marginBottom: "0.5rem" }}>
          renews {formatDate()}
        </p>
      )}
      {hasUpcomingCarge && (
        <a className="musio-link" href="#" onClick={() => handleCancel()}>
          Cancel Subscription
        </a>
      )}
    </div>
  );
};

const MusioLicenseInput = (props) => {
  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[abcdefABCDEF0-9]/;
    if (!regex.test(key)) {
      e.preventDefault();
      return;
    }

    var text = e.target.value;
    if (text.length == 19) {
      e.preventDefault();
      return;
    }
    if (text.length == 3 || text.length == 8 || text.length == 13) {
      e.target.value += key;
      e.target.value += "-";
      e.preventDefault();
    }
  }

  const handlePaste = (e) => {
    var clipboardData, pastedData;
    // Stop data actually being pasted into div
    e.stopPropagation();
    e.preventDefault();
    // Get pasted data via clipboard API
    clipboardData = e.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData("Text").trim();
    if (pastedData.startsWith("MUS-")) {
      pastedData = pastedData.substring(4);
    }

    //conform
    var regex =
      /[abcdefABCDEF0-9]{4}-[abcdefABCDEF0-9]{4}-[abcdefABCDEF0-9]{4}-[abcdefABCDEF0-9]{4}/;
    if (regex.test(pastedData)) {
      e.target.value = pastedData;
      props.setLicense(pastedData);
    }
  };

  const handleChange = (event) => {
    props.setLicense(event.target.value);
  };

  const handleFocus = (event) => event.target.select();

  return (
    <div className="redeem-license-container">
      <p>
        <span className="subtext">MUS-</span>
        <input
          onFocus={handleFocus}
          onPaste={handlePaste}
          onChange={handleChange}
          className="license-input"
          placeholder="XXXX-XXXX-XXXX-XXXX"
          type="text"
          onKeyPress={handleKeyPress}
        />
      </p>
    </div>
  );
};

const RedeemMembership = (props) => {
  const [license, setLicense] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const submitLicenseCode = (e) => {
    e.preventDefault();
    setLoading(true);
    const cleanLicense = "MUS-" + license.trim();
    axios
      .post("/api/v1/license-codes/redeem", { token: cleanLicense })
      .then((resp) => {
        PubSub.publish("RefreshMembership", true);
        props.onReturn();
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.response.data.error);
        setLoading(false);
      });
  };

  return (
    <div className="membership-container">
      <form onSubmit={submitLicenseCode}>
        <div className="redeem-title">
          <h3>Redeem License Code</h3>
          <p className="subtext">
            If you are currently subscribed, your membership will be canceled
            and the license will begin at the end of the billing cycle
          </p>
          <ErrorMessage text={errorMessage} />
        </div>
        <MusioLicenseInput setLicense={setLicense} />
        <div className="confirm-container">
          <MusioButton
            buttonType="squared"
            size="small"
            background="grey"
            onClick={props.onReturn}
            text="go back"
          />
          <MusioButton
            buttonType="squared"
            size="small"
            loading={loading}
            text="submit"
            disabled={license.length < 19}
          />
        </div>
      </form>
    </div>
  );
};

export default MembershipPanel;
