import axios from 'axios';
import React from 'react';
import {Link} from 'react-router-dom'

import MusioLogo from 'components/MusioLogo';
import ErrorMessage from 'components/ErrorMessage';

import './style.css';
import MusioButton from 'components/MusioButton';
import MusioCheckbox from 'components/MusioCheckbox';
import { isDesktopDevice } from 'utils';


class SignUp extends React.Component {
  constructor(props) {
    super(props);
    //extract params
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let coupon = params.get('coupon')
    let next = params.get('next');
    console.log(next, coupon);
    if (['annualSubscription', 'monthlySubscription', 'trial', 'login'].includes(next) == false) {
      next = 'trial';
    }
    let isTrial = next === 'trial' || next === null;

    this.state = {
      signupName: '',
      signupEmail: '',
      signupPassword: '',
      emailConsentChecked: true,
      agreementChecked: false,
      signupComplete: false,
      signupError: '',
      isLoading: false,
      trialFlow: isTrial,
      next: next,
      coupon: coupon
    };
    

    this.signupClicked = this.signupClicked.bind(this);
  };

  componentDidMount(){
  }

  signupClicked(e) {
    this.setState({isLoading:true});
    e.preventDefault();

    this.setState({signupError: ''});

    if (this.state.signupName === '') {
      this.setState({signupError: 'please fill out your name.', isLoading:false});
      return;
    }

    if (this.state.signupEmail === '') {
      this.setState({signupError: 'you must fill out your email.', isLoading:false});
      return;
    }

    if (this.state.signupPassword === ''){
      this.setState({signupError: 'you must fill out your password.', isLoading:false});
      return;
    }

    if (this.state.agreementChecked === false) {
      this.setState({signupError: 'you must agree to our Terms of Service & Privacy Policy.', isLoading:false});
      return;
    }



    //CHECK PASS WORD REQS. 8+ char and 64 or less
    if (this.state.signupPassword.length < 8) {
      //set some error in this.state to be rendered
      //red text underneath showing the error to the user, right of the text field for now
      this.setState({signupError: 'password must be at least 8 characters long.', isLoading:false});
      return;
    } else if (this.state.signupPassword.length > 64) {
      this.setState({signupError: 'password cannot be more than 64 characters long.', isLoading:false});
      return;
    }

    let wantsNews = this.state.emailConsentChecked;
    let data = {
      contactName: this.state.signupName,
      authId: this.state.signupEmail,
      password: this.state.signupPassword,
      wantsNews: wantsNews
    }
    if (this.state.next != null) {
      data.verificationNext = this.state.next;
    }
    if (this.state.coupon != null) {
      data.onboardingCoupon = this.state.coupon;
    }
    axios.post('/api/v1/signup', data)
    .then(resp => {
      if (resp.data.success) {
        this.setState({signupComplete: true, isLoading:false});
        window.location.href="/signupComplete";
      } else if (resp.data.passwordConstraint) {
        this.setState({signupError: resp.data.passwordConstraint, isLoading:false})
      }
    })
    .catch(err => {
      if (err.response){
        if (err.response.status){
          let status = err.response.status
            if (status == 422) {
              this.setState({signupError: err.response.data.error.passwordConstraint, isLoading:false})
              return;
            }
        }
        this.setState({signupError: err.response.data.error, isLoading:false})
      }
    });
  };

  render() {
    //SUBMISSION WAS COMPLETED
    // if (this.state.signupComplete) {
    //   return (
    //     <div className="onboarding-container">
    //       <MusioLogo />
    //       <div className="onboarding-spacer" />

    //       <h1 className="onboarding-header">account created!</h1>

    //       <p className="subtext">an account verification link has been sent to your email.</p>
    //     </div>
    //   );
    // }

    let is_mobile = isDesktopDevice() === false;

    //INCOMPLETE SUBMISSION
    return (
      <div className='onboarding-container'>
        <MusioLogo />
        <div className="onboarding-spacer" />

        <ErrorMessage text={this.state.signupError} />

        {this.state.trialFlow ?
          <div>
            <h1 className='onboarding-header'>Start your free 30-day trial.</h1>
            <p className="subtext">no credit-card required!</p>
          </div>
        :
          <div>
            <h1 className='onboarding-header'>Signup to get started.</h1>
            <p className="subtext">create your account</p>
          </div>
        }
       
        <form className='onboarding-form' onSubmit={this.signupClicked}>
          <input background-color= '#404040' type="text" placeholder=' name...' onChange={
            (e)=>this.setState({signupName: e.target.value})}/>
          <input background-color= '#404040' type='email' placeholder=' email...'  onChange={
            (e)=>this.setState({signupEmail: e.target.value})}
            pattern="^[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]+$" required />
          <input type='password' placeholder=' password...'  minLength="8" onChange={
            (e)=>this.setState({signupPassword: e.target.value})}/>

          <div className='checkbox-flex pointer' onClick={
            (e)=>{ this.setState({emailConsentChecked: !this.state.emailConsentChecked}); }
          }>
            <MusioCheckbox checked={this.state.emailConsentChecked}/>
            <p className="subtext">I'd like to receive news about updates and instrument releases via email</p>
          </div>

          <div className='checkbox-flex'onClick={
            (e)=>{ this.setState({agreementChecked: !this.state.agreementChecked}); }
          }>
            <MusioCheckbox checked={this.state.agreementChecked}/>
            <p>
              <span className="subtext">I agree with the{is_mobile && <br/>}</span>&nbsp;
              <a className="onboarding-link" href="https://storage.googleapis.com/musio-public/MUSIO%20TERMS%20AND%20CONDITIONS%20OF%20USE.pdf" target="_blank">Terms of Service</a>
              <span className="subtext">&nbsp;&&nbsp;</span>
              <a className="onboarding-link" href="https://storage.googleapis.com/musio-public/MUSIO%20PRIVACY%20POLICY.pdf" target="_blank">Privacy Policy</a>
            </p>
          </div>
          <div className="onboarding-spacer" />
          <div><MusioButton text="sign up" /></div>
          <Link className="onboarding-link" to="/login">
            log in
          </Link>
        </form>
      </div>
    );
  };

};

export default SignUp;
